import React, { useEffect } from "react";
import Header from "../components/Header";
import CalculatorWidget, {
  MSPComparison,
  PricingPlan,
  ServicePlans,
} from "../components/PricingpageComp";
import Footer from "../components/Footer";

const PricingPage = () => {
  useEffect(()=>{
    window.scroll(0,0)
  })
  return (
    <>
      <Header />
      <PricingPlan />
      <MSPComparison/>
      <ServicePlans />
      <CalculatorWidget/>
      <Footer/>
    </>
  );
};

export default PricingPage;
